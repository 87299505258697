/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { AccountFieldsFragmentDoc } from '../account/accounts.generated';
import { api } from '@store/baseApi';
export type EscrowOrderFieldsFragment = {
  __typename?: 'EscrowOrder';
  id: string;
  message?: string | null;
  escrowScript: string;
  escrowAddress: string;
  nonce: string;
  releaseTxid?: string | null;
  returnTxid?: string | null;
  price: number;
  amount: number;
  status: Types.EscrowOrderStatus;
  createdAt: any;
  updatedAt: any;
  arbitratorAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  buyerAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  sellerAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  moderatorAccount: {
    __typename?: 'Account';
    id: number;
    publicKey?: string | null;
    hash160?: string | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
  };
  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
  offer: { __typename?: 'Offer'; postId: string; message: string };
  escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any }> | null;
  dispute?: { __typename?: 'Dispute'; createdBy: string; reason?: string | null; status: Types.DisputeStatus } | null;
};

export type EscrowOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type EscrowOrderQuery = {
  __typename?: 'Query';
  escrowOrder: {
    __typename?: 'EscrowOrder';
    id: string;
    message?: string | null;
    escrowScript: string;
    escrowAddress: string;
    nonce: string;
    releaseTxid?: string | null;
    returnTxid?: string | null;
    price: number;
    amount: number;
    status: Types.EscrowOrderStatus;
    createdAt: any;
    updatedAt: any;
    arbitratorAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    buyerAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    sellerAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    moderatorAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
    offer: { __typename?: 'Offer'; postId: string; message: string };
    escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any }> | null;
    dispute?: { __typename?: 'Dispute'; createdBy: string; reason?: string | null; status: Types.DisputeStatus } | null;
  };
};

export type GetModeratorAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetModeratorAccountQuery = {
  __typename?: 'Query';
  getModeratorAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type GetRandomArbitratorAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRandomArbitratorAccountQuery = {
  __typename?: 'Query';
  getRandomArbitratorAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type CreateEscrowOrderMutationVariables = Types.Exact<{
  input: Types.CreateEscrowOrderInput;
}>;

export type CreateEscrowOrderMutation = {
  __typename?: 'Mutation';
  createEscrowOrder: { __typename?: 'EscrowOrder'; id: string };
};

export type UpdateEscrowOrderStatusMutationVariables = Types.Exact<{
  input: Types.UpdateEscrowOrderInput;
}>;

export type UpdateEscrowOrderStatusMutation = {
  __typename?: 'Mutation';
  updateEscrowOrderStatus: {
    __typename?: 'EscrowOrder';
    id: string;
    message?: string | null;
    price: number;
    amount: number;
    status: Types.EscrowOrderStatus;
    createdAt: any;
    updatedAt: any;
  };
};

export const EscrowOrderFieldsFragmentDoc = `
    fragment EscrowOrderFields on EscrowOrder {
  id
  message
  arbitratorAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  buyerAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  sellerAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  moderatorAccount {
    id
    publicKey
    hash160
    telegramId
    telegramUsername
  }
  paymentMethod {
    id
    name
  }
  offer {
    postId
    message
  }
  escrowScript
  escrowAddress
  nonce
  escrowTxids {
    txid
    value
  }
  dispute {
    createdBy
    reason
    status
  }
  releaseTxid
  returnTxid
  price
  amount
  status
  createdAt
  updatedAt
}
    `;
export const EscrowOrderDocument = `
    query EscrowOrder($id: String!) {
  escrowOrder(id: $id) {
    ...EscrowOrderFields
  }
}
    ${EscrowOrderFieldsFragmentDoc}`;
export const GetModeratorAccountDocument = `
    query GetModeratorAccount {
  getModeratorAccount {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const GetRandomArbitratorAccountDocument = `
    query GetRandomArbitratorAccount {
  getRandomArbitratorAccount {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const CreateEscrowOrderDocument = `
    mutation CreateEscrowOrder($input: CreateEscrowOrderInput!) {
  createEscrowOrder(data: $input) {
    id
  }
}
    `;
export const UpdateEscrowOrderStatusDocument = `
    mutation UpdateEscrowOrderStatus($input: UpdateEscrowOrderInput!) {
  updateEscrowOrderStatus(data: $input) {
    id
    message
    price
    amount
    status
    createdAt
    updatedAt
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    EscrowOrder: build.query<EscrowOrderQuery, EscrowOrderQueryVariables>({
      query: variables => ({ document: EscrowOrderDocument, variables })
    }),
    GetModeratorAccount: build.query<GetModeratorAccountQuery, GetModeratorAccountQueryVariables | void>({
      query: variables => ({ document: GetModeratorAccountDocument, variables })
    }),
    GetRandomArbitratorAccount: build.query<
      GetRandomArbitratorAccountQuery,
      GetRandomArbitratorAccountQueryVariables | void
    >({
      query: variables => ({ document: GetRandomArbitratorAccountDocument, variables })
    }),
    CreateEscrowOrder: build.mutation<CreateEscrowOrderMutation, CreateEscrowOrderMutationVariables>({
      query: variables => ({ document: CreateEscrowOrderDocument, variables })
    }),
    UpdateEscrowOrderStatus: build.mutation<UpdateEscrowOrderStatusMutation, UpdateEscrowOrderStatusMutationVariables>({
      query: variables => ({ document: UpdateEscrowOrderStatusDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
