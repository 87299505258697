/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import {
  PageInfoFieldsFragmentDoc,
  PostMeiliPageInfoFieldsFragmentDoc
} from '../../graphql/fragments/page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type DisputeFieldsFragment = {
  __typename?: 'Dispute';
  id: string;
  reason?: string | null;
  createdBy: string;
  status: Types.DisputeStatus;
  createdAt: any;
  updatedAt: any;
  escrowOrder: { __typename?: 'EscrowOrder'; id: string };
};

export type DisputeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type DisputeQuery = {
  __typename?: 'Query';
  dispute: {
    __typename?: 'Dispute';
    id: string;
    reason?: string | null;
    createdBy: string;
    status: Types.DisputeStatus;
    createdAt: any;
    updatedAt: any;
    escrowOrder: { __typename?: 'EscrowOrder'; id: string };
  };
};

export type DisputesByAccountIdQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  orderBy?: Types.InputMaybe<Types.DisputeOrder>;
}>;

export type DisputesByAccountIdQuery = {
  __typename?: 'Query';
  allDisputesByAccountId: {
    __typename?: 'DisputeConnection';
    edges?: Array<{
      __typename?: 'DisputeEdge';
      cursor: string;
      node: {
        __typename?: 'Dispute';
        id: string;
        reason?: string | null;
        createdBy: string;
        status: Types.DisputeStatus;
        createdAt: any;
        updatedAt: any;
        escrowOrder: { __typename?: 'EscrowOrder'; id: string };
      };
    }> | null;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
};

export type CreateDisputeMutationVariables = Types.Exact<{
  input: Types.CreateDisputeInput;
}>;

export type CreateDisputeMutation = { __typename?: 'Mutation'; createDispute: { __typename?: 'Dispute'; id: string } };

export type UpdateDisputeMutationVariables = Types.Exact<{
  input: Types.UpdateDisputeInput;
}>;

export type UpdateDisputeMutation = { __typename?: 'Mutation'; updateDispute: { __typename?: 'Dispute'; id: string } };

export const DisputeFieldsFragmentDoc = `
    fragment DisputeFields on Dispute {
  id
  escrowOrder {
    id
  }
  reason
  createdBy
  status
  createdAt
  updatedAt
}
    `;
export const DisputeDocument = `
    query Dispute($id: String!) {
  dispute(id: $id) {
    ...DisputeFields
  }
}
    ${DisputeFieldsFragmentDoc}`;
export const DisputesByAccountIdDocument = `
    query DisputesByAccountId($after: String, $before: String, $first: Int, $last: Int, $orderBy: DisputeOrder) {
  allDisputesByAccountId(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
  ) {
    edges {
      cursor
      node {
        ...DisputeFields
      }
    }
    pageInfo {
      ...PageInfoFields
    }
  }
}
    ${DisputeFieldsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;
export const CreateDisputeDocument = `
    mutation CreateDispute($input: CreateDisputeInput!) {
  createDispute(data: $input) {
    id
  }
}
    `;
export const UpdateDisputeDocument = `
    mutation UpdateDispute($input: UpdateDisputeInput!) {
  updateDispute(data: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Dispute: build.query<DisputeQuery, DisputeQueryVariables>({
      query: variables => ({ document: DisputeDocument, variables })
    }),
    DisputesByAccountId: build.query<DisputesByAccountIdQuery, DisputesByAccountIdQueryVariables | void>({
      query: variables => ({ document: DisputesByAccountIdDocument, variables })
    }),
    CreateDispute: build.mutation<CreateDisputeMutation, CreateDisputeMutationVariables>({
      query: variables => ({ document: CreateDisputeDocument, variables })
    }),
    UpdateDispute: build.mutation<UpdateDisputeMutation, UpdateDisputeMutationVariables>({
      query: variables => ({ document: UpdateDisputeDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
